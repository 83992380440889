import { datadogRum } from '@datadog/browser-rum';

export default {
    install(Vue) {
        // Configurar o Datadog RUM com seu aplicativo's clientToken e applicationId
        datadogRum.init({
            applicationId: '282c0141-1ab3-4f20-9993-2eb469230e58',
            clientToken: 'pubd3083fd1820161d2c13d4056db809670',
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: 'datadoghq.com',
            service: 'consignado-webadmin-prd',
            env: 'prod',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0', 
      sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });

        // Expondo o objeto datadogRum globalmente no Vue
        Vue.prototype.$datadogRum = datadogRum;
    },
};